<template>
  <v-main>
    <v-layout column mt-4 mx-4 rounded px-3 py-4 minh-content>
      <router-view />
    </v-layout>

    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
export default {
  name: 'DashboardCoreView',

  components: {
    // DashboardCoreFooter: () => import('./Footer')
  }
}
</script>
